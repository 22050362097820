<template>
  <div>
    <!-- Entity Handler -->
    <EntityHandlerSidebar
      v-if="tableReady && !reportView"
      v-model="isEntityHandlerSidebarActive"
      :module="config.module"
      :entity="entity"
      :duplicate="isEntityDuplicate"
      :clear-entity-data="clearEntityData"
      :add-entity="addEntity"
      :update-entity="updateEntity"
      :form-fields="formFields"
      :size="sidebarSize"
      :import-csv="importCsv"
      :extra-validation="extraValidation"
      @fieldUpdated="fieldUpdated"
    />
    <b-modal
      v-if="allowEditColumns"
      id="editColumnsModal"
      :visible="editColumns"
      :title="$t('Edit Columns')"
      header-bg-variant="primary"
      size="md"
      centered
      @change="editColumns = false"
    >
      <b-table
        ref="columnsTable"
        :items="mainTableColumns"
        :fields="editColumnFields"
        small
        striped
        responsive
        stacked="sm"
      >
        <template #cell(title)="data">
          {{ data.item.label }}
        </template>
        <template #cell(selection)="data">
          <b-form-checkbox v-model="data.item.visible" />
        </template>
      </b-table>
      <template #modal-footer="{ close }">
        <b-button
          variant="secondary"
          @click="close()"
        >
          {{ $t('Close') }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="deleteConfirmationModal"
      :visible="deleteModalItem.id > 0"
      :title="$t('Delete Item')"
      header-bg-variant="danger"
      size="md"
      centered
      @close="deleteModalItem.id = 0"
    >
      <p>
        {{ $t('Proceed Delete') }}
      </p>
      <template #modal-footer="{ close }">
        <b-button
          variant="secondary"
          @click="close()"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          id="confirmDeleteButton"
          variant="danger"
          data-dismiss="modal"
          @click="handleDeleteClick(), close()"
        >
          {{ $t('Delete') }}
        </b-button>
      </template>
    </b-modal>
    <!-- Filters -->
    <b-card-actions
      v-if="filters"
      :title="$t('Filters')"
      :collapsed="!config.filtersOpen"
      action-collapse
      no-body
      :class="{
        'mb-1': config.limited,
      }"
    >
      <b-card-body>
        <b-row>
          <b-col
            v-for="(filter, index) in filters"
            :key="filter.name"
            cols="12"
            md="4"
            class="mb-1"
          >
            <label>{{ filter.label }}</label>
            <div
              v-if="filter.type === 'date'"
              class="position-relative"
            >
              <flat-pickr
                v-model="filterValues[index]"
                class="form-control flat-pickr-inherit"
                :config="{
                  dateFormat: 'Y-m-d',
                  minDate: filter.minDateFieldIdx
                    ? filterVals[filter.minDateFieldIdx]
                    : null,
                  maxDate:
                    filter.maxDateFieldIdx && filterVals[filter.maxDateFieldIdx]
                      ? filterVals[filter.maxDateFieldIdx]
                      : filter.maxDate
                        ? filter.maxDate
                        : null,
                  allowInput: false,
                }"
                :placeholder="$t('YYYY-MM-DD')"
                @on-change="updateFilterValues($event, index)"
              />
              <span
                v-if="filterVals[index]"
                class="flat-pickr-clear"
                @click="updateFilterValues(null, index)"
              >
                x
              </span>
            </div>
            <v-select
              v-else-if="filter.type === 'boolean'"
              label="name"
              :value="filterVals[index]"
              :options="getBooleanOptions"
              class="w-100"
              :reduce="val => val.id"
              append-to-body
              clearable
              @input="updateFilterValues($event, index)"
            />
            <v-select
              v-else-if="filter.options"
              label="title"
              :value="filterVals[index]"
              :options="filter.options"
              class="w-100"
              :reduce="val => val.id"
              filterable
              :clearable="!filter.value"
              append-to-body
              @input="updateFilterValues($event, index)"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card-actions>

    <b-alert
      v-if="config.limited"
      show
      class="p-1"
      :variant="remainingEntries < 1 ? 'danger' : 'secondary'"
    >
      {{
        $t('RemainingEntries', {
          remaining: remainingEntries,
          limit: config.limited,
        })
      }}
    </b-alert>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div :class="embedded ? 'my-2' : 'm-2'">
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="4"
            lg="3"
            xl="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-xl-0"
          >
            <b-button-group class="w-100">
              <json-excel
                :fetch="fetchAllEntities"
                :fields="exportFields"
                :name="config.title.plural + '.xls'"
                class="w-50"
              >
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  class="w-100 right-radius-reset"
                >
                  Excel
                </b-button>
              </json-excel>
              <json-excel
                :fetch="fetchAllEntities"
                :fields="exportFields"
                :name="config.title.plural + '.csv'"
                type="csv"
                :escape-csv="false"
                class="w-50"
              >
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  class="w-100 left-radius-reset"
                >
                  CSV
                </b-button>
              </json-excel>
            </b-button-group>
          </b-col>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="8"
            lg="3"
            xl="3"
            class="d-flex align-items-center justify-content-end mb-1 mb-xl-0"
          >
            <label>{{ $t('Entries') }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              append-to-body
              class="per-page-selector d-inline-block ml-50 mr-lg-50"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="12"
            lg="12"
            xl="7"
          >
            <b-row class="d-flex align-items-center justify-content-end">
              <b-col
                cols="12"
                :md="showHeadRowButton ? 8 : 12"
                class="d-flex mb-1 mb-md-0"
              >
                <b-form-input
                  v-if="!reportView"
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search') + '...'"
                />
                <b-button
                  v-if="allowEditColumns"
                  v-b-tooltip.hover
                  :title="$t('Columns')"
                  size="sm"
                  variant="outline-secondary"
                  @click="editColumns = true"
                >
                  <feather-icon
                    icon="ColumnsIcon"
                    size="16"
                  />
                </b-button>
              </b-col>

              <b-col
                v-if="showHeadRowButton"
                cols="12"
                md="4"
              >
                <b-button
                  v-if="config.limited && remainingEntries < 1"
                  variant="success"
                  to="/subscriptions"
                  class="w-100"
                >
                  <span class="text-nowrap">{{ $t('Upgrade') }}</span>
                </b-button>

                <b-button
                  v-else-if="!reportView && allowInsert && hasInsertBtn"
                  variant="primary"
                  :to="getCreateLink()"
                  class="w-100"
                  @click="handleAddClick()"
                >
                  <span class="text-nowrap">
                    {{ $t('Add') }} {{ config.title.single }}
                  </span>
                </b-button>
                <b-dropdown
                  v-if="reportView"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="w-100 ml-md-1"
                  right
                  :text="$t('Download full report')"
                  variant="primary"
                  :disabled="downloadingFile"
                >
                  <b-dropdown-item
                    v-for="format in exportFileFormats"
                    :key="format"
                    @click="downloadFile(format)"
                  >
                    {{ format.toUpperCase() }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

      <b-table
        v-if="tableReady"
        ref="refEntitiesTable"
        class="position-relative entity-table"
        :items="fetchEntities"
        :fields="activeColumns"
        responsive
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$i18n.t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(id)="data">
          <b-link
            v-if="allowEdit && !hideEdit"
            :to="getEditLink(data.item.id)"
            class="font-weight-bold d-block text-nowrap"
            @click="handleEntityClick(data.item)"
          >
            {{ data.item.id }}
          </b-link>
          <span v-else>
            {{ data.item.id }}
          </span>
        </template>
        <!-- Column: name -->
        <template #cell(title)="data">
          <b-link
            v-if="allowEdit && !hideEdit"
            :to="getEditLink(data.item.id)"
            class="font-weight-bold d-block text-nowrap"
            @click="handleEntityClick(data.item)"
          >
            {{ data.item.title }}
          </b-link>
          <span v-else>
            {{ data.item.title }}
          </span>
        </template>
        <template #cell(lastName)="data">
          <b-link
            v-if="allowEdit && !hideEdit"
            :to="getEditLink(data.item.id)"
            class="font-weight-bold d-block"
            @click="handleEntityClick(data.item)"
          >
            {{ data.item.lastName }}
          </b-link>
          <span v-else>
            {{ data.item.lastName }}
          </span>
        </template>
        <template #cell(vimeoId)="data">
          <b-link
            :href="'https://vimeo.com/' +
              data.item.vimeoId +
              (data.item.vimeoHash ? '/' + data.item.vimeoHash : '')
              "
            target="_blank"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.vimeoId }}
          </b-link>
        </template>
        <template
          v-for="field in formattedColumns"
          #[`cell(${field.key})`]="data"
        >
          <feather-icon
            v-if="field.type == 'boolean'"
            :key="field.key"
            :icon="data.item[field.key] ? 'CheckIcon' : 'XIcon'"
            size="18"
          />
          <b-img
            v-else-if="field.type == 'image'"
            :key="field.key + 'image'"
            :src="data.item[field.key]"
            alt=""
            thumbnail
            width="100"
          />
          <b-avatar
            v-else-if="field.type == 'status'"
            :id="`entity-row-${data.item.id}`"
            :key="field.key + 'status'"
            :variant="`light-${resolveStatusVariantAndIcon(data.item[field.key]).variant
              }`"
            size="32"
          >
            <feather-icon :icon="resolveStatusVariantAndIcon(data.item[field.key]).icon" />

            <b-tooltip
              v-if="data.item[field.key] === true || data.item[field.key] === false
              "
              :target="`entity-row-${data.item.id}`"
              placement="top"
            >
              <p class="mb-0">
                {{
                  data.item[field.key] === true ? $t('Approved') : $t('Pending')
                }}
              </p>
            </b-tooltip>
          </b-avatar>
          <span
            v-else-if="field.type == 'translate'"
            :key="field.key + 'translate'"
          >
            {{ $t(data.item[field.key]) }}
          </span>
          <template v-else-if="field.type == 'list'">
            <span
              v-for="(innerItem, index) in data.item[field.key]"
              :key="innerItem.id"
            >
              {{
                innerItem.title +
                (index == data.item[field.key].length - 1 ? '' : ', ')
              }}
            </span>
          </template>
          <template v-else-if="field.type == 'date'">
            {{ formatDate(data.item[field.key]) }}
          </template>
          <template v-else-if="field.type == 'daymonth'">
            {{
              formatDate(data.item[field.key], {
                month: 'long',
                day: 'numeric',
              })
            }}
          </template>
          <template v-else-if="field.type == 'datetime'">
            {{
              formatDate(data.item[field.key], {
                dateStyle: 'medium',
                timeStyle: 'medium',
                hour12: false,
              })
            }}
          </template>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <template v-if="inlineActions">
            <div class="w-100px">
              <b-button
                v-if="detailsView"
                v-b-tooltip.hover
                :title="$t('Details')"
                variant="success"
                size="sm"
                class="mr-50 mb-25"
                :to="getViewLink(data.item.id)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>

              <b-button
                v-if="editShouldRender(data.item)"
                v-b-tooltip.hover
                :title="$t('Edit')"
                variant="warning"
                size="sm"
                class="mr-50 mb-25"
                :to="getEditLink(data.item.id)"
                @click="handleEntityClick(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>

              <b-button
                v-if="allowDuplicate && editShouldRender(data.item)"
                v-b-tooltip.hover
                :title="$t('Duplicate')"
                variant="secondary"
                size="sm"
                class="mr-50 mb-25"
                :to="getCopyLink(data.item.id)"
                @click="handleDuplicateClick(data.item)"
              >
                <feather-icon icon="CopyIcon" />
              </b-button>

              <template v-for="extraAction in extraActions">
                <b-button
                  v-if="extraActionShouldRender(extraAction, data.item)"
                  :key="extraAction.endpoint"
                  v-b-tooltip.hover
                  :title="$t(extraAction.title)"
                  :variant="extraAction.variant ? extraAction.variant : 'info'"
                  size="sm"
                  class="mr-50 mb-25"
                  :href="extraAction.href
                    ? extraAction.href +
                    (extraAction.appendField
                      ? data.item[extraAction.appendField]
                      : '')
                    : null
                    "
                  target="_blank"
                  @click="handleExtraActionClick(data.item, extraAction)"
                >
                  <feather-icon :icon="extraAction.icon" />
                </b-button>
              </template>

              <b-button
                v-if="enableDisableToggle"
                v-b-tooltip.hover
                :title="$t(data.item.enabled ? 'Disable' : 'Enable')"
                :variant="data.item.enabled ? 'success' : 'warning'"
                size="sm"
                class="mr-50 mb-25"
                :disabled="disableActions"
                @click="toggleEnableDisable(data.item)"
              >
                <feather-icon :icon="data.item.enabled ? 'ToggleRightIcon' : 'ToggleLeftIcon'" />
              </b-button>

              <b-button
                v-if="deleteShouldRender(data.item)"
                v-b-tooltip.hover
                :title="$t('Delete')"
                variant="danger"
                size="sm"
                class="mr-50 mb-25"
                @click="updateDeleteModalItem(data.item)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
          </template>

          <b-dropdown
            v-else
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="detailsView"
              :to="getViewLink(data.item.id)"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">{{ $t('Details') }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="editShouldRender(data.item)"
              :to="getEditLink(data.item.id)"
              @click="handleEntityClick(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('Edit') }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="allowDuplicate && editShouldRender(data.item)"
              :to="getCopyLink(data.item.id)"
              @click="handleDuplicateClick(data.item)"
            >
              <feather-icon icon="CopyIcon" />
              <span class="align-middle ml-50">{{ $t('Duplicate') }}</span>
            </b-dropdown-item>

            <template v-for="extraAction in extraActions">
              <b-dropdown-item
                v-if="extraActionShouldRender(extraAction, data.item)"
                :key="extraAction.title"
                :disabled="disableActions"
                :href="extraAction.href
                  ? extraAction.href +
                  (extraAction.appendField
                    ? data.item[extraAction.appendField]
                    : '')
                  : null
                  "
                target="_blank"
                @click="handleExtraActionClick(data.item, extraAction)"
              >
                <feather-icon :icon="extraAction.icon" />
                <span class="align-middle ml-50">{{
                  $t(extraAction.title)
                }}</span>
              </b-dropdown-item>
            </template>

            <b-dropdown-item
              v-if="enableDisableToggle"
              :disabled="disableActions"
              @click="toggleEnableDisable(data.item)"
            >
              <feather-icon :icon="data.item.enabled ? 'ToggleRightIcon' : 'ToggleLeftIcon'" />
              <span class="align-middle ml-50">{{
                $t(data.item.enabled ? 'Disable' : 'Enable')
              }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="deleteShouldRender(data.item)"
              @click="updateDeleteModalItem(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('Delete') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
              $t('ShowingEntries', {
                from: dataMeta.from,
                to: dataMeta.to,
                entries: dataMeta.of,
              })
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import axios from '@/libs/axios'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import JsonExcel from 'vue-json-excel'
import Ripple from 'vue-ripple-directive'
import { formatDate, avatarText } from '@core/utils/filter'
import { getUserRights } from '@/auth/utils'
import EntityHandlerSidebar from '@/layouts/entity/EntityHandlerSidebar.vue'
import { Vimeo } from 'vimeo'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    vSelect,
    flatPickr,
    EntityHandlerSidebar,
    JsonExcel,
    BCardActions,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        store: null,
        module: 'example',
        endpoint: 'example',
        route: 'example',
        sortBy: 'id',
        title: {
          single: 'Example',
          plural: 'Examples',
        },
        limited: null,
      }),
    },
    columns: {
      type: Array,
      default() {
        return [
          { key: 'id', label: this.$t('id'), sortable: true },
          { key: 'name', label: this.$t('name'), sortable: true },
          { key: 'description', label: this.$t('description'), sortable: true },
          { key: 'actions', label: this.$t('actions') },
        ]
      },
      required: false,
    },
    embedded: {
      type: Boolean,
      default: false,
      required: false,
    },
    detailsView: {
      type: Boolean,
      default: false,
      required: false,
    },
    reportView: {
      type: Boolean,
      default: false,
      required: false,
    },
    singleEdit: {
      type: Boolean,
      default: false,
      required: false,
    },
    allowDuplicate: {
      type: Boolean,
      default: true,
      required: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
      required: false,
    },
    hideDelete: {
      type: Boolean,
      default: false,
      required: false,
    },
    hasInsertBtn: {
      type: Boolean,
      default: true,
      required: false,
    },
    filters: {
      type: Array,
      default: null,
      required: false,
    },
    filterVals: {
      type: Array,
      default: null,
      required: false,
    },
    customQuery: {
      type: Object,
      default: () => { },
      required: false,
    },
    resultsPerPage: {
      type: Number,
      default: 10,
      required: false,
    },
    formFields: {
      type: Array,
      default() {
        return [
          {
            id: 'name',
            type: 'text',
            label: this.$t('name'),
            required: true,
          },
          {
            id: 'description',
            type: 'textarea',
            label: this.$t('Description'),
          },
        ]
      },
      required: false,
    },
    formattedColumns: {
      type: Array,
      default: null,
      required: false,
    },
    authority: {
      type: String,
      default: '',
      required: false,
    },
    extraActions: {
      type: Array,
      default: null,
      required: false,
    },
    inlineActions: {
      type: Boolean,
      default: true,
      required: false,
    },
    importCsv: {
      type: String,
      default: '',
      required: false,
    },
    sidebarSize: {
      type: String,
      default: 'lg',
      required: false,
    },
    enableDisableToggle: {
      type: Boolean,
      default: false,
      required: false,
    },
    descSort: {
      type: Boolean,
      default: true,
      required: false,
    },
    allowEditColumns: {
      type: Boolean,
      default: false,
      required: false,
    },
    exportExtraColumns: {
      type: Object,
      default: null,
      required: false,
    },
    extraValidation: {
      type: Function,
      default: null,
    },
  },
  setup() {
    return {
      // Filter
      formatDate,
      avatarText,
    }
  },
  data() {
    return {
      allowInsert: false,
      allowEdit: false,
      allowDelete: false,
      downloadingFile: false,
      isEntityHandlerSidebarActive: false,
      isEntityDuplicate: false,
      editColumns: false,
      blankEntity: null,
      entity: { id: null },
      perPage: 10,
      totalRows: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: !this.descSort,
      entities: [],
      tableReady: false,
      updateFilterDebounce: null,
      disableActions: false,
      deleteModalItem: { id: 0 },
      tableColumns: [],
    }
  },
  computed: {
    filterValues() {
      return this.filterVals
    },
    remainingEntries() {
      return this.config.limited ? this.config.limited - this.totalRows : null
    },
    extraParams() {
      const params = []
      this.filters.forEach(filter => {
        params.push(filter.name)
      })
      return params
    },
    mainColumns() {
      return this.columns.filter(col => col.key !== 'actions')
    },
    mainTableColumns() {
      return this.tableColumns.filter(col => col.key !== 'actions')
    },
    activeColumns() {
      return this.tableColumns.filter(col => col.visible)
    },
    editColumnFields() {
      return [
        { key: 'title', label: this.$t('title') },
        { key: 'selection', label: this.$t('show') },
      ]
    },
    exportFields() {
      const jsonFields = {}
      for (const obj of this.mainColumns) {
        const label = obj.label || obj.key
        if (
          this.formattedColumns &&
          this.formattedColumns.filter(
            column => column.key == obj.key && column.type == 'list',
          ).length > 0
        ) {
          jsonFields[label] = {
            field: obj.key,
            callback: value => {
              let result = ''
              for (let index = 0; index < value.length; index++) {
                result +=
                  value[index].title + (index == value.length - 1 ? '' : ', ')
              }
              return result
            },
          }
        } else {
          jsonFields[label] = obj.key
        }
      }
      if (this.exportExtraColumns) {
        this.exportExtraColumns.extraColumns.forEach(col => {
          jsonFields[col.title] = {
            field: this.exportExtraColumns.key,
            callback: value => {
              const result = value.find(
                item => item[this.exportExtraColumns.parent].id === col.id,
              )
              return result ? result.value : ''
            },
          }
        })
      }
      return jsonFields
    },
    dataMeta() {
      const localItemsCount = this.$refs.refEntitiesTable
        ? this.$refs.refEntitiesTable.localItems.length
        : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalRows,
      }
    },

    exportFileFormats() {
      return ['pdf', 'html', 'xlsx', 'csv', 'xml']
    },

    getBooleanOptions() {
      return [
        { id: null, name: this.$t('All') },
        { id: '1', name: this.$t('Yes') },
        { id: '0', name: this.$t('No') },
      ]
    },

    showHeadRowButton() {
      return (
        (this.config.limited && this.remainingEntries < 1) ||
        this.reportView ||
        (this.allowInsert && this.hasInsertBtn)
      )
    },
  },
  created() {
    const rights = getUserRights()

    this.allowInsert = rights.includes(`${this.authority}_Create`)
    this.allowEdit = rights.includes(`${this.authority}_Edit`)
    this.allowDelete = rights.includes(`${this.authority}_Delete`)

    this.perPage = this.resultsPerPage

    if (this.config.sortBy) {
      this.sortBy = this.config.sortBy
    }
    if (this.filters) {
      this.filters.forEach((filter, index) => {
        if (filter.value) {
          // eslint-disable-next-line vue/no-mutating-props
          this.filterVals[index] = filter.value
        }
      })
    }
    // Register module
    if (!this.$store.hasModule(this.config.module)) {
      this.$store.registerModule(this.config.module, this.config.store)
      this.$store.commit(`${this.config.module}/setConfig`, this.config)
    }
  },
  mounted() {
    this.initBlankEntity()
    this.$watch('currentPage', this.refetchData)
    this.$watch('perPage', this.refetchData)
    this.$watch('searchQuery', this.refetchData)
    this.tableColumns = this.columns.map(column => ({
      ...{ visible: true },
      ...column,
    }))
    this.tableReady = true
  },
  methods: {
    updateDeleteModalItem(deleteItem) {
      this.deleteModalItem = JSON.parse(JSON.stringify(deleteItem))
    },
    initBlankEntity() {
      this.formFields.forEach(field => {
        if (field.initVal) {
          this.entity[field.id] = field.initVal
        } else if (field.type === 'select' || field.type === 'selectmultiple') {
          this.entity[field.id] = null
        } else if (field.type === 'checkbox') {
          this.entity[field.id] = false
        } else {
          this.entity[field.id] = ''
        }
      })
      this.blankEntity = JSON.parse(JSON.stringify(this.entity))
    },

    getQueryParams() {
      const queryParams = {
        page: this.currentPage - 1,
        size: this.perPage,
        sort: `${this.sortBy},${this.isSortDirDesc ? 'asc' : 'desc'}`,
      }
      if (this.searchQuery.length > 0) {
        queryParams['q'] = this.searchQuery
      }

      if (this.filters) {
        this.extraParams.forEach((value, index) => {
          if (this.filterVals[index]) {
            queryParams[value] = this.filterVals[index]
          }
        })
      }

      if (this.customQuery) {
        Object.keys(this.customQuery).forEach(key => {
          queryParams[key] = this.customQuery[key]
        })
      }

      return queryParams
    },

    refetchData() {
      this.$refs.refEntitiesTable.refresh()
      this.disableActions = false
    },

    fetchEntities() {
      return this.$store
        .dispatch(`${this.config.endpoint}/getEntities`, this.getQueryParams())
        .then(response => {
          const items = response.data.content
          this.totalRows = response.data.totalElements
          this.entities = items || []
          return this.entities
        })
        .catch(() => {
          this.showToast({
            title: `Error fetching ${this.config.title.plural} list`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          })
        })
    },

    fetchAllEntities() {
      const params = {
        ...this.getQueryParams(),
        size: 5000,
      }

      return this.$store
        .dispatch(`${this.config.endpoint}/getEntities`, params)
        .then(response => {
          const items = response.data.content
          this.totalRows = response.data.totalElements
          this.entities = items || []
          return this.entities
        })
        .catch(() => {
          this.showToast({
            title: `Error fetching ${this.config.title.plural} list`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          })
        })
    },
    resolveStatusVariantAndIcon(status) {
      if (status === true || status === 'ACTIVE') {
        return { variant: 'success', icon: 'CheckCircleIcon' }
      }
      if (status === 'DECLINED') return { variant: 'danger', icon: 'XIcon' }
      if (status === 'DELETED') {
        return { variant: 'danger', icon: 'Trash2Icon' }
      }

      return { variant: 'warning', icon: 'InfoIcon' }
    },

    getViewLink(itemId) {
      return { name: `${this.config.route}-view`, params: { id: itemId } }
    },

    getCreateLink() {
      if (this.config.insertRoute) {
        return this.config.insertRoute
      }
      return this.singleEdit ? { name: `${this.config.route}-edit` } : '#'
    },

    getEditLink(itemId) {
      return this.singleEdit
        ? { name: `${this.config.route}-edit`, params: { id: itemId } }
        : '#'
    },

    getCopyLink(itemId) {
      return this.singleEdit
        ? { name: `${this.config.route}-edit`, params: { duplicate: itemId } }
        : '#'
    },

    getOriginDuplicateLink(itemId) {
      return this.singleEdit
        ? {
          name: `${this.config.route}-edit`,
          params: { duplicate: itemId, keepOrigin: true },
        }
        : '#'
    },

    editShouldRender(item) {
      return (
        this.allowEdit &&
        !this.hideEdit &&
        (!this.config.editRenderCondition ||
          item[this.config.editRenderCondition.key] ===
          this.config.editRenderCondition.value) &&
        (!this.config.editPropertyExists ||
          item[this.config.editPropertyExists]) &&
        (!this.config.editPropertyNotExists ||
          !item[this.config.editPropertyNotExists])
      )
    },

    deleteShouldRender(item) {
      return (
        this.allowDelete &&
        !this.hideDelete &&
        (!this.config.deleteRenderCondition ||
          item[this.config.deleteRenderCondition.key] ===
          this.config.deleteRenderCondition.value) &&
        (!this.config.deletePropertyExists ||
          item[this.config.deletePropertyExists]) &&
        (!this.config.deletePropertyNotExists ||
          !item[this.config.deletePropertyNotExists])
      )
    },

    extraActionShouldRender(action, item) {
      return (
        (!action.hasOwnProperty('authorityValidation') ||
          action.authorityValidation) &&
        // If no render condition is given or the key matches the desired value continue
        (!action.renderCondition ||
          item[action.renderCondition.key] === action.renderCondition.value) &&
        (!action.propertyExists || item[action.propertyExists]) &&
        (!action.propertyNotExists || !item[action.propertyNotExists])
      )
    },

    updateFilterValues(newVal, index) {
      if (this.updateFilterDebounce) return

      // eslint-disable-next-line vue/no-mutating-props
      this.filterVals[index] = newVal

      this.updateFilterDebounce = setTimeout(() => {
        this.refetchData()
        this.updateFilterDebounce = null
      }, 100)
      this.$emit('filtersUpdated', this.filterVals)
    },

    clearEntityData() {
      this.entity = JSON.parse(JSON.stringify(this.blankEntity))
    },

    handleAddClick() {
      if (!this.singleEdit) {
        this.isEntityHandlerSidebarActive = true
      }
    },

    handleEntityClick(entityData) {
      this.handleItemClick(entityData, false)
    },

    handleDuplicateClick(entityData) {
      this.handleItemClick(entityData, true)
    },

    handleItemClick(entityData, isEntityDuplicate) {
      if (!this.singleEdit) {
        this.isEntityDuplicate = isEntityDuplicate
        this.entity = JSON.parse(JSON.stringify(entityData))
        this.isEntityHandlerSidebarActive = true
        this.fieldUpdated()
      }
    },
    fieldUpdated() {
      this.$emit('entityUpdated', this.entity)
    },

    handleDeleteClick() {
      const item = this.deleteModalItem
      if (item.vimeoId) {
        const vim = new Vimeo(
          process.env.VUE_APP_VIMEO_CLIENT_ID,
          process.env.VUE_APP_VIMEO_CLIENT_SECRET,
          process.env.VUE_APP_VIMEO_ACCESS_TOKEN,
        )
        vim.request(
          {
            method: 'DELETE',
            path: `/videos/${item.vimeoId}`,
          },
          (error, body) => {
            console.log(error)
            console.log(body)
          },
        )
      }
      this.$store
        .dispatch(`${this.config.endpoint}/deleteEntity`, item.id)
        .then(() => {
          this.refetchData()
        })
        .catch(() => {
          this.showToast({
            title:
              'The entity could not be deleted! Check the foreign key constraints first.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          })
        })
    },

    async handleExtraActionClick(item, action) {
      if (!action.href) {
        this.disableActions = true
        if (action.callback) {
          let link
          switch (action.callback) {
            case 'getEditLink':
              link = this.getEditLink(item.id)
              break;
            case 'getViewLink':
              link = this.getViewLink(item.id)
              break;
            case 'relationDuplicate':
              link = this.getOriginDuplicateLink(item.id)
              break;
          }
          this.$router.push(link)
        } else {
          await axios.get(
            `${this.config.endpoint}/${action.endpoint}/${item.id}`,
          )
        }
        this.refetchData()
      }
    },

    async toggleEnableDisable(item) {
      this.disableActions = true
      await axios.put(`${this.config.endpoint}/enabled`, {
        id: item.id,
        enabled: !item.enabled,
      })

      this.refetchData()
    },

    downloadFile(format) {
      this.downloadingFile = true

      const payload = { format, params: this.getQueryParams() }

      this.$store
        .dispatch(`${this.config.endpoint}/downloadReport`, payload)
        .then(response => {
          const fileName = `${this.config.title.single
            }_${new Date().toISOString()}.${format}`
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          this.downloadingFile = false
        })
    },

    addEntity(data) {
      this.saveEntity('add', data)
    },

    updateEntity(data) {
      this.saveEntity('update', data)
    },

    saveEntity(saveType, data) {
      this.$store
        .dispatch(`${this.config.endpoint}/${saveType}Entity`, data)
        .then(() => {
          this.handleEntitySave()
        })
        .catch(error => {
          this.handleSaveError(error)
        })
    },

    handleEntitySave() {
      this.isEntityHandlerSidebarActive = false
      this.refetchData()
      this.$store.commit('app-common/clearCache')
    },

    handleSaveError(error) {
      this.showToast({
        title: this.$t('Save Failure'),
        icon: 'AlertTriangleIcon',
        variant: 'danger',
        text: this.$t(error.response.data.message),
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.flat-pickr-inherit {
  background-color: inherit;
}

.flat-pickr-clear {
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 1.2;
  cursor: pointer;
}

.right-radius-reset {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.left-radius-reset {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0 !important;
}
</style>

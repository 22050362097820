<template>
  <b-sidebar
    id="sidebar-entity-handler"
    :visible="isEntityHandlerSidebarActive"
    bg-variant="white"
    :sidebar-class="'sidebar-' + size"
    shadow
    backdrop
    no-header
    right
    @change="val => $emit('update:is-entity-sidebar-active', val)"
    @hidden="clearEntityData"
    @shown="initForm"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{
            (entity.id ? $t('Update') : $t('Add')) + ' ' + config.title.single
          }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- BODY -->
      <validation-observer
        v-slot="{ invalid, validate }"
        ref="modalFormObserver"
      >
        <!-- Form -->
        <b-form
          v-if="isEntityHandlerSidebarActive"
          class="p-2"
          autocomplete="off"
          @submit.prevent="validate().then(submit)"
        >
          <b-row>
            <b-col
              v-for="(formField, index) in formFields"
              :key="formField.name ? formField.name : formField.id"
              cols="12"
              :md="formField.colSize ? formField.colSize : 12"
              :class="formField.hidden ? 'hidden' : 'mt-1'"
            >
              <FormField
                :module="module"
                :form-field="formField"
                :entity="formField.parent ? entity[formField.parent] : entity"
                :autofocus="index == 0"
                :checkbox-click="handleCheckboxClick"
                :dropdown-click="handleDropdownClick"
                @fieldUpdated="fieldUpdated"
              />
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="invalid"
            >
              {{ $t('Save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>

      <ImportCSV
        v-if="entity.id && importCsv"
        :entity="entity"
        :endpoint="importCsv"
      />
    </template>
  </b-sidebar>
</template>

<script>
import { BRow, BCol, BSidebar, BForm, BButton } from 'bootstrap-vue'
import { required, alphaNum } from '@validations'
import { ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import FormField from '@/layouts/entity/FormField.vue'
import ImportCSV from '@/layouts/entity/ImportCSV.vue'

export default {
  components: {
    BRow,
    BCol,
    BSidebar,
    BForm,
    BButton,
    FormField,
    ImportCSV,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEntityHandlerSidebarActive',
    event: 'update:is-entity-sidebar-active',
  },
  props: {
    module: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'lg',
      required: false,
    },
    isEntityHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    duplicate: {
      type: Boolean,
      default: false,
      required: false,
    },
    clearEntityData: {
      type: Function,
      required: true,
    },
    addEntity: {
      type: Function,
      required: true,
    },
    updateEntity: {
      type: Function,
      required: true,
    },
    formFields: {
      type: Array,
      required: true,
    },
    importCsv: {
      type: String,
      default: '',
      required: false,
    },
    extraValidation: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
    }
  },
  computed: {
    config() {
      return this.$store.getters[`${this.module}/config`]
    },
  },
  methods: {
    initForm() {
      this.formFields.forEach(formField => {
        if (formField.type === 'checkbox') {
          this.handleCheckboxClick(formField.id)
        }
      })
    },
    handleCheckboxClick(formFieldId) {
      if (!this.entity[formFieldId]) {
        this.formFields.forEach(formField => {
          if (formField.dependency === formFieldId) {
            // eslint-disable-next-line vue/no-mutating-props
            this.entity[formField.id] = null
          }
        })
      }
    },
    handleDropdownClick(formFieldId) {
      if (this.$parent.$parent.handleDropdownClick) {
        this.$parent.$parent.handleDropdownClick(
          formFieldId,
          this.entity[formFieldId],
        )
      }
    },
    fieldUpdated() {
      this.$emit('fieldUpdated')
    },
    async submit() {
      let isValid = await this.$refs.modalFormObserver.validate()
      if (this.extraValidation) {
        this.customValidationMsg = this.extraValidation(
          this.entity,
        )
        if (this.customValidationMsg.length > 0) {
          isValid = false
          this.showToast({
            title: this.customValidationMsg,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          })
        }
      }
      if (isValid) {
        if (this.duplicate) {
          // eslint-disable-next-line vue/no-mutating-props
          this.entity.id = null
        }
        if (this.entity.id) {
          this.updateEntity(this.entity)
        } else {
          this.addEntity(this.entity)
        }
        this.$nextTick(() => {
          this.$refs.modalFormObserver.reset()
        })
      }
    },
  },
}
</script>

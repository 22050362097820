<template>
  <EntityList
    :config="config"
    :columns="columns"
    :form-fields="formFields"
    authority="Congress_Management_Congress_Services"
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from './speakerInvitationStoreModule'

export default {
  components: {
    EntityList,
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'congress',
        endpoint: 'congress',
        route: 'congress',
        title: {
          single: this.$t('Congress'),
          plural: this.$t('Congress'),
        },
      }
    },

    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'name', label: this.$t('name'), sortable: true },
        { key: 'actions', label: this.$t('actions') },
      ]
    },

    formFields() {
      return [
        {
          id: 'name',
          type: 'text',
          label: this.$t('name'),
          required: true,
          rules: 'min:3',
        },
      ]
    },
  },
}
</script>
